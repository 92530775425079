<template>
    <div class="text-right text-muted" id="score-container" @click="togglePopup">
        <img :src="this.image" class="img-fluid">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="currentColor"><path d="M12.001 2.085a9.914 9.914 0 00-9.916 9.916c0 5.476 4.438 9.914 9.916 9.914a9.913 9.913 0 009.914-9.914c0-5.478-4.438-9.916-9.914-9.916zm.001 18a8.083 8.083 0 110-16.167 8.083 8.083 0 010 16.167z"/><path d="M11.766 6.688c-2.5 0-3.219 2.188-3.219 2.188l1.411.854s.298-.791.901-1.229c.516-.375 1.625-.625 2.219.125.701.885-.17 1.587-1.078 2.719C11.047 12.531 11 15 11 15h1.969s.135-2.318 1.041-3.381c.603-.707 1.443-1.338 1.443-2.494s-1.187-2.437-3.687-2.437zM11 16h2v2h-2z"/></g>
        </svg>

        <transition name="fade">
            <div id="score-popup" v-if="popupIsShown" @click="togglePopup">
                <div>
                    <p class="text-left mr-3">La scoring scale di Reference assegna alla tua rete un benchmark qualitativo espresso da un valore minimo D ad un valore massimo S.</p>

                    <img style="object-fit: contain;" class="img-fluid" src="../assets/scoring_scale.png">

                    <button type="button" class="close" aria-label="Chiudi" @click="togglePopup">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                popupIsShown: false
            };
        },

        props: [
            'value',
        ],

        computed: {
            image() {
                return `/img/score/${this.value.toLowerCase()}.png`;
            }
        },

        methods: {
            togglePopup() {
                this.popupIsShown = ! this.popupIsShown;
            },
        }
    };
</script>

<style scoped>
    #score-container {
        cursor: pointer;
    }

    #score-container > svg {
        width: 18px;
        height: 18px;
        margin-top: -36px; /* Twice the SVG size... */
    }

    #score-popup {
        position: absolute;
        bottom: 48px; /* The size of the "?" icon * 2 + 12... */
        left: 0;
        right: 0;
        margin: 0 1rem;
        padding: 1rem;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.10);
        z-index: 1000;
    }

    @media (min-width: 992px) {
        #score-popup {
            left: 9rem;
            width: 300px;
        }
    }

    #score-popup .close {
        position: absolute;
        top: 0.25rem;
        right: 0.5rem;
        padding: 0.5rem;
        color: #6c757d;
        text-shadow: none;
    }

    /* Vue.js transition classess... */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .25s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
