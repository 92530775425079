<template>
    <div class="px-4 pt-2 pb-3">
        <loading :active.sync="loading" color="rgb(36, 71, 136)" transition="fade"/>

        <div v-if="!loading" class="text-center">
            <a class="mb-3 d-block" href="/" target="_blank">
                <img class="w-100" src="../assets/reference.png" alt="RetImpresa Registry">
            </a>

            <Score :value="this.submission.grade"/>

            <p class="mb-0">
                <span class="my-1 d-block font-weight-bold">{{ this.submission.name }}</span>
                ha completato con successo la valutazione della Rete
            </p>
        </div>
    </div>
</template>

<script>
    import Score from "@/components/Score.vue";

    import * as axios from 'axios';
    import Loading from 'vue-loading-overlay';

    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        components: {
            Loading,
            Score
        },

        data() {
            return {
                loading: true,
                submission: {},
            };
        },

        props: {
            //
        },

        async mounted() {
            this.submission = await this.getEmbedData();
            this.loading = false;
        },

        methods: {
            /**
             * Retrieve embed data from the backend.
             *
             * @return {Promise<void>}
             */
            async getEmbedData() {
                try {
                    const response = await axios
                        .get(`${process.env.VUE_APP_SURVEY_REST}/api/v1/embeds/${this.$route.params.submission_id}`);

                    return response.data.data;
                } catch (e) {
                    console.log('Errore durante la richiesta dei dati.');
                }
            },

            /**
             * Get the primary goal.
             *
             * @return object
             */
            getPrimaryGoal() {
                console.log( this.data);
                return this.data.submission.goals.filter(goal => goal.primary)[1];
            }
        },
    };
</script>

<style>
    .cd-primary-nav {
        display: none;
    }
</style>
